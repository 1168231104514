import React, { Fragment } from 'react';

import { TGameData, TParticipant } from '../../types';

import { isLastDay } from '../../helpers';
import { translate } from '../../translations';
import Alert from '../common/Alert';

type Props = {
  participant: TParticipant;
  game: TGameData;
};

const Alerts = ({ participant, game }: Props) => (
  <Fragment>
    {!participant.canPlay && !isLastDay(game.endDate) && (
      <Alert title={game.alreadyPlayedText || translate('alreadyPlayedTitle')} />
    )}
    {!participant.canPlay && isLastDay(game.endDate) && (
      <Alert title={game.alreadyPlayedText || translate('alreadyPlayedAndLastDayTitle')} />
    )}
    {!participant.canPlay && game.participationPolicy === 'per-game' && (
      <Alert title={game.alreadyPlayedText || translate('alreadyPlayedOnceTimeGameTitle')} />
    )}
  </Fragment>
);

export default Alerts;
