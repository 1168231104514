import { TGameData, TParticipant } from './types';

import { adventCalendarId, classicWheelId, estivalWheelId, fortuneWheelId, sirhaWheelId } from './components/games';

import logoEmxemple from './assets/logo-exemple.png';

export const classicWheelMockGameData: {
  data: {
    game: TGameData;
    participant: TParticipant;
  };
} = {
  data: {
    game: {
      id: '1',
      title: 'Test Game',
      description:
        'Tentez de remporter votre accès au Village Partenaire et à la zone VIP concours, en tournant notre roue de la chance.',
      startDate: '2024-10-10',
      endDate: '2042-11-14',
      logo: logoEmxemple,
      animationType: classicWheelId,
      client: 'Test Client',
      sourceProgramUrl: 'https://www.google.com',
      sourceProgramName: 'Test Source Program',
      gameRule: 'Test Game Rule',
      enabled: true,
      participationPolicy: 'once',
    },
    participant: {
      firstname: 'John',
      lastname: 'Doe',
      email: 'john.doe@example.com',
      canPlay: true,
    },
  },
};

export const estivalWheelMockGameData: {
  data: {
    game: TGameData;
    participant: TParticipant;
  };
} = {
  data: {
    game: {
      id: '1',
      title: 'Test Game',
      description:
        'Tentez de remporter votre accès au Village Partenaire et à la zone VIP concours, en tournant notre roue de la chance.',
      startDate: '2024-10-10',
      endDate: '2042-11-14',
      logo: logoEmxemple,
      animationType: estivalWheelId,
      client: 'Test Client',
      sourceProgramUrl: 'https://www.google.com',
      sourceProgramName: 'Test Source Program',
      gameRule: 'Test Game Rule',
      enabled: true,
      participationPolicy: 'once',
    },
    participant: {
      firstname: 'John',
      lastname: 'Doe',
      email: 'john.doe@example.com',
      canPlay: true,
    },
  },
};

export const fortuneWheelMockGameData: {
  data: {
    game: TGameData;
    participant: TParticipant;
  };
} = {
  data: {
    game: {
      id: '1',
      title: 'Test Game',
      description:
        'Tentez de remporter votre accès au Village Partenaire et à la zone VIP concours, en tournant notre roue de la chance.',
      startDate: '2024-10-10',
      endDate: '2042-11-14',
      logo: logoEmxemple,
      animationType: fortuneWheelId,
      client: 'Test Client',
      sourceProgramUrl: 'https://www.google.com',
      sourceProgramName: 'Test Source Program',
      gameRule: 'Test Game Rule',
      enabled: true,
      participationPolicy: 'once',
    },
    participant: {
      firstname: 'John',
      lastname: 'Doe',
      email: 'john.doe@example.com',
      canPlay: true,
    },
  },
};

export const adventCalendarMockGameData: {
  data: {
    game: TGameData;
    participant: TParticipant;
  };
} = {
  data: {
    game: {
      id: '1',
      title: 'Test Game',
      description:
        'Tentez de remporter votre accès au Village Partenaire et à la zone VIP concours, en tournant notre roue de la chance.',
      startDate: '2024-10-10',
      endDate: '2042-11-14',
      logo: logoEmxemple,
      animationType: adventCalendarId,
      client: 'Test Client',
      sourceProgramUrl: 'https://www.google.com',
      sourceProgramName: 'Test Source Program',
      gameRule: 'Test Game Rule',
      enabled: true,
      participationPolicy: 'once',
    },
    participant: {
      firstname: 'John',
      lastname: 'Doe',
      email: 'john.doe@example.com',
      canPlay: true,
    },
  },
};

export const sirhaWheelMockGameData: {
  data: {
    game: TGameData;
    participant: TParticipant;
  };
} = {
  data: {
    game: {
      id: '1',
      title: 'Test Game',
      description:
        'Tentez de remporter votre accès au Village Partenaire et à la zone VIP concours, en tournant notre roue de la chance.',
      startDate: '2024-10-10',
      endDate: '2042-11-14',
      logo: logoEmxemple,
      animationType: sirhaWheelId,
      client: 'Test Client',
      sourceProgramUrl: 'https://www.google.com',
      sourceProgramName: 'Test Source Program',
      gameRule: 'Test Game Rule',
      enabled: true,
      participationPolicy: 'once',
      winningText: '<div><strong>🎉 Congratulations!</strong><br/>Test Winning Text</div>',
      alreadyPlayedText: '<div>Test Already Played Text</div>',
    },
    participant: {
      firstname: 'John',
      lastname: 'Doe',
      email: 'john.doe@example.com',
      canPlay: false,
    },
  },
};

export const sirhaWheelAlreadyPlayedMockGameData: {
  data: {
    game: TGameData;
    participant: TParticipant;
  };
} = {
  data: {
    game: {
      id: '1',
      title: 'Test Game',
      description:
        'Tentez de remporter votre accès au Village Partenaire et à la zone VIP concours, en tournant notre roue de la chance.',
      startDate: '2024-10-10',
      endDate: '2042-11-14',
      logo: logoEmxemple,
      animationType: sirhaWheelId,
      client: 'Test Client',
      sourceProgramUrl: 'https://www.google.com',
      sourceProgramName: 'Test Source Program',
      gameRule: 'Test Game Rule',
      enabled: true,
      participationPolicy: 'per-game',
    },
    participant: {
      firstname: 'John',
      lastname: 'Doe',
      email: 'john.doe@example.com',
      canPlay: true,
    },
  },
};
